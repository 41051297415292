import React, { useEffect, useCallback, useRef } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import DOMPurify from "dompurify";
import { marked } from "marked";
import TurndownService from "turndown";
import styles from "./WYSIWYGEditor.module.scss";
import { ReactComponent as UndoIcon } from "../../icons/undo.svg";
import { ReactComponent as RedoIcon } from "../../icons/redo.svg";
import { ReactComponent as BoldIcon } from "../../icons/bold.svg";
import { ReactComponent as ItalicIcon } from "../../icons/italic.svg";
import { ReactComponent as TextIcon } from "../../icons/text.svg";
import { ReactComponent as OrderedListIcon } from "../../icons/ordered-list.svg";
import { ReactComponent as UnorderedListIcon } from "../../icons/unordered-list.svg";

const turndownService = new TurndownService({
  headingStyle: "atx",
  bulletListMarker: "*",
  codeBlockStyle: "fenced",
});

const WYSIWYGEditor = ({ field, form, ...props }) => {
  const lastSelectionRef = useRef(null);
  const isUpdatingRef = useRef(false);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
      }),
      Placeholder.configure({
        placeholder: props.placeholder || "Write your text here...",
      }),
    ],
    editorProps: {
      attributes: {
        class: styles.prose,
      },
      handleDOMEvents: {
        keydown: (view, event) => {
          if (event.key === "Enter") {
            lastSelectionRef.current = editor.state.selection;
          }
          return false;
        },
      },
    },
    onSelectionUpdate: ({ editor }) => {
      if (!isUpdatingRef.current) {
        lastSelectionRef.current = editor.state.selection;
      }
    },
    onUpdate: ({ editor }) => {
      if (isUpdatingRef.current) return;

      isUpdatingRef.current = true;
      const currentSelection = editor.state.selection;

      try {
        const html = editor.getHTML();
        const sanitizedHtml = DOMPurify.sanitize(html);
        const markdown = turndownService.turndown(sanitizedHtml);

        // Update form value
        form.setFieldValue(field.name, markdown, false);

        // Restore selection after a short delay
        requestAnimationFrame(() => {
          if (editor && !editor.isDestroyed) {
            editor.commands.setTextSelection(currentSelection.from);
          }
          isUpdatingRef.current = false;
        });
      } catch (error) {
        console.error("Error updating content:", error);
        isUpdatingRef.current = false;
      }
    },
  });

  // Handle initial content
  useEffect(() => {
    if (
      editor &&
      !editor.isDestroyed &&
      field.value &&
      !isUpdatingRef.current
    ) {
      try {
        isUpdatingRef.current = true;
        const html = DOMPurify.sanitize(
          marked.parse(field.value, { breaks: true }),
        );

        if (editor.getHTML() !== html) {
          editor.commands.setContent(html);
        }

        isUpdatingRef.current = false;
      } catch (error) {
        console.error("Error setting initial content:", error);
        isUpdatingRef.current = false;
      }
    }
  }, [editor, field.value]);

  if (!editor) {
    return null;
  }

  const MenuBar = () => (
    <div className={styles.toolbar}>
      {/* Undo Button */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().undo().run();
        }}
        className={styles.toolbarButton}
        title="Undo"
        aria-label="Undo"
      >
        <UndoIcon />
      </button>

      {/* Redo Button */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().redo().run();
        }}
        className={styles.toolbarButton}
        title="Redo"
        aria-label="Redo"
      >
        <RedoIcon />
      </button>

      {/* Bold Button */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleBold().run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("bold") ? styles.active : ""
        }`}
        title="Bold"
        aria-label="Bold"
      >
        <BoldIcon className={styles.icon} />
      </button>

      {/* Italic Button */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleItalic().run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("italic") ? styles.active : ""
        }`}
        title="Italic"
        aria-label="Italic"
      >
        <ItalicIcon className={styles.icon} />
      </button>

      {/* Bullet List Button */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleBulletList().run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("bulletList") ? styles.active : ""
        }`}
        title="Bullet List"
        aria-label="Bullet List"
      >
        <UnorderedListIcon />
      </button>

      {/* Numbered List Button */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleOrderedList().run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("orderedList") ? styles.active : ""
        }`}
        title="Numbered List"
        aria-label="Numbered List"
      >
        <OrderedListIcon />
      </button>
      {/* Normal/Regular Text */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().setParagraph().run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("paragraph") ? styles.active : ""
        }`}
        title="Normal Text"
        aria-label="Normal Text"
      >
        <TextIcon className={styles.icon} />
      </button>

      {/* Heading Level 1 */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleHeading({ level: 1 }).run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("heading", { level: 1 }) ? styles.active : ""
        }`}
        title="Heading 1"
        aria-label="Heading 1"
      >
        H1
      </button>

      {/* Heading Level 2 */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleHeading({ level: 2 }).run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("heading", { level: 2 }) ? styles.active : ""
        }`}
        title="Heading 2"
        aria-label="Heading 2"
      >
        H2
      </button>

      {/* Heading Level 3 */}
      <button
        type="button"
        onMouseDown={(event) => {
          event.preventDefault();
          editor.chain().focus().toggleHeading({ level: 3 }).run();
        }}
        className={`${styles.toolbarButton} ${
          editor.isActive("heading", { level: 3 }) ? styles.active : ""
        }`}
        title="Heading 3"
        aria-label="Heading 3"
      >
        H3
      </button>
    </div>
  );

  return (
    <div className={styles.editorContainer}>
      <MenuBar />
      <EditorContent editor={editor} />
    </div>
  );
};

export default WYSIWYGEditor;
