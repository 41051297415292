import React, { useState, useRef } from "react";
import axiosInstance from "../../utils/utils"; // Ensure axios is imported
import styles from "./Upload.module.scss";
import AddFileIcon from "../../icons/add-folder.svg";
import RemoveFileIcon from "../../icons/cross-dark.svg";
import { useParams } from "react-router-dom";

const Upload = ({ questionId, onChange }) => {
  const [files, setFiles] = useState([]); // Hold uploaded files
  const [uploading, setUploading] = useState(false); // Track upload status
  const fileInputRef = useRef(null);
  const sessionToken = localStorage.getItem("session_token");
  const { orgId } = useParams();

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const uploadedFiles = [];

    setUploading(true);

    for (const file of selectedFiles) {
      try {
        const formData = new FormData();
        formData.append("org_id", orgId);
        formData.append("file", file);

        const response = await axiosInstance.post("/s3/file", formData, {
          headers: {
            "session-token": sessionToken,
            "Content-Type": "multipart/form-data",
          },
        });

        // Adjust path extraction based on actual response structure
        const filePath = response.data.path || response.data; // Update this based on response structure
        uploadedFiles.push({
          name: file.name,
          path: filePath,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    const newFiles = [...files, ...uploadedFiles];

    setFiles(newFiles);

    // Pass only the file paths to the parent
    onChange(newFiles.map((file) => file.path));
    setUploading(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onChange(updatedFiles.map((file) => file.path)); // Pass only the file paths to parent
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.uploadWrapper}>
        <div className={styles.leftSide}>
          <label
            htmlFor={`file-upload-${questionId}`}
            className={styles.upload}
          >
            <img src={AddFileIcon} alt="Upload" />
            <div className={styles.text}>Upload files</div>
          </label>
          {files.length > 0 && (
            <div className={styles.selectedFiles}>
              {files.map((file, index) => (
                <div key={index} className={styles.selectedFile}>
                  <div className={styles.text}>File: {file.name}</div>{" "}
                  {/* Show the file name */}
                  <div
                    onClick={() => handleRemoveFile(index)}
                    className={styles.removeButton}
                  >
                    <img src={RemoveFileIcon} alt="Remove" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          className={styles.fileInput}
          id={`file-upload-${questionId}`}
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
        />
      </div>
      {uploading && <div className={styles.uploading}>Uploading...</div>}
    </div>
  );
};

export default Upload;
