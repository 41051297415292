import React, { useEffect, useRef, useState } from "react";
import styles from "./NewImprovementProjectModal.module.scss";
import Modal from "../components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../redux/modalStore";
import { Field, Form, Formik } from "formik";
import Textarea from "../components/Textarea/Textarea";
import DatePickerField from "../components/FormikComponents/DatePickerField";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import * as Yup from "yup";
import axiosInstance from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganizationData } from "../actions/organization";
import ConfirmationPopup from "./ConfirmationPopup";
import FormikCheckbox from "../components/FormikComponents/FormikCheckBox/FormikCheckBox";
import { ReactComponent as LockIcon } from "../icons/lock.svg";

const NewImprovementProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openedModal = useSelector((state) => state.modal.openedModal);
  const [loading, setLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [dotCount, setDotCount] = useState(0);

  // States for typing effect
  const [typingDescription, setTypingDescription] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullDescription, setFullDescription] = useState("");

  const formikRef = useRef(null);
  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name cannot be empty."),
    description: Yup.string().required("Please provide a description."),
  });

  // Dot loading effect
  useEffect(() => {
    let dotInterval;
    if (isGenerating) {
      dotInterval = setInterval(() => {
        setDotCount((prevCount) => (prevCount + 1) % 4); // Cycle between 0, 1, 2, 3 (representing 0, 1, 2, 3 dots)
      }, 500); // Update every 500ms
    } else {
      setDotCount(0); // Reset dots when not generating
    }
    return () => clearInterval(dotInterval); // Cleanup interval on component unmount or when generation stops
  }, [isGenerating]);

  const handleCloseModal = (dirty, resetForm) => {
    if (dirty) {
      setShowConfirmationPopup(true);
    } else {
      dispatch(hideModal());

      // Reset typingDescription to empty when closing the modal
      setTypingDescription("");
      setFullDescription(""); // Reset the full description too

      if (formikRef.current) {
        formikRef.current.setFieldValue("description", ""); // Reset Formik description field
      }

      if (resetForm && typeof resetForm === "function") {
        resetForm();
      }
    }
  };

  const handleConfirmClose = (resetForm) => {
    setShowConfirmationPopup(false);
    dispatch(hideModal());

    // Reset typingDescription to empty when closing the modal
    setTypingDescription("");
    setFullDescription(""); // Reset the full description too

    if (formikRef.current) {
      formikRef.current.setFieldValue("description", ""); // Reset Formik description field
    }

    resetForm();
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false);
  };
  const formatDate = (inputDate) => {
    if (!inputDate) {
      return new Date().toISOString().split("T")[0];
    }
    return new Date(inputDate).toISOString().split("T")[0];
  };

  const findingKeys = Array.isArray(openedModal?.data)
    ? openedModal.data.map((finding) => finding.key)
    : [];

  console.log(openedModal?.data, "opened modal data");

  const convertedArray = findingKeys?.map((item) => {
    // Extract the numeric part of the string using a regular expression
    const match = item.match(/\d+/);

    // Check if the match was successful
    if (match) {
      // Convert the extracted numeric part to a number
      return parseInt(match[0], 10);
    } else {
      // Handle the case where no numeric part was found
      throw new Error(`Unable to parse the string as an integer: ${item}`);
    }
  });

  // Fetch description from the AI when modal is opened
  useEffect(() => {
    if (openedModal?.name === "new_improvement_modal" && openedModal?.data) {
      setIsGenerating(true); // Show the indicator when generation starts
      fetchImprovementDescription(openedModal.data);
    }
  }, [openedModal]);

  const fetchImprovementDescription = async (data) => {
    try {
      const response = await axiosInstance.post(
        "/ai/generate_improvement",
        data,
        {
          headers: { "session-token": sessionToken },
        },
      );
      const aiGeneratedDescription = response.data.improvement || "";

      // Set the full description and start the typing effect
      setFullDescription(aiGeneratedDescription);
      setCurrentIndex(0); // Reset the typing index to start from the beginning
      setIsTypingComplete(false); // Reset typing completion state
    } catch (error) {
      console.error("Error generating improvement description:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  // Typing effect logic
  useEffect(() => {
    if (currentIndex < fullDescription.length) {
      const typingTimeout = setTimeout(() => {
        setTypingDescription((prev) => prev + fullDescription[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, 20); // Speed of typing

      return () => clearTimeout(typingTimeout); // Clear timeout if component unmounts or description changes
    } else {
      setIsTypingComplete(true); // Mark typing as complete
      if (formikRef.current) {
        // Once typing is finished, set the description in Formik
        formikRef.current.setFieldValue("description", fullDescription);
      }
    }
  }, [currentIndex, fullDescription]);

  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);

      // First POST request
      const createImprovementResponse = await axiosInstance.post(
        `/nocodb/improvements?org_id=${orgId}`,
        [
          {
            name: values.name,
            description: values.description,
            date: formatDate(values.date),
            private: values.private,
          },
        ],
        {
          headers: { "session-token": sessionToken },
        },
      );

      const targetId = createImprovementResponse.data;

      // Second POST request
      const linkFindingsResponse = await axiosInstance.post(
        `/nocodb/link_findings?org_id=${orgId}&link_target=Improvement&target_id=${targetId}`,
        convertedArray, // Sending the keys from all objects in openedModal.data
        {
          headers: { "session-token": sessionToken },
        },
      );

      // update the status of the linked findings to "In Progress"
      if (convertedArray && convertedArray.length > 0) {
        const updateFindingStatusPromises = convertedArray.map((findingId) => {
          return axiosInstance.patch(
            `/nocodb/findings?org_id=${orgId}`,
            [
              {
                id: findingId,
                status: "In Progress",
              },
            ],
            {
              headers: {
                "Content-Type": "application/json",
                "session-token": sessionToken,
              },
            },
          );
        });

        // Wait for all the findings to be updated
        await Promise.all(updateFindingStatusPromises);
      }

      // Reset the form after the improvement is successfully saved
      actions.resetForm();
      // Close the modal after saving
      handleCloseModal();
    } catch (error) {
      console.error("Error creating improvement project:", error);
    } finally {
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          true,
          true,
          false,
          false,
          false,
        ),
      );

      setLoading(false);
      actions.setSubmitting(false);
      dispatch(hideModal());
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: "",
        description: "",
        date: "",
        private: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setLoading(true);
        handleSubmit(values, actions);
      }}
      enableReinitialize
    >
      {({ values, dirty, setFieldValue, resetForm }) => {
        return (
          <>
            <Modal
              name="new_improvement_modal"
              title="New Improvement Project"
              onClose={() => handleCloseModal(dirty, resetForm)}
              width="95vw"
              height="95vh"
            >
              <Form className={styles.content}>
                <div className={styles.row}>
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    component={Input}
                    className={styles.input}
                  />
                </div>
                <div className={styles.privacyContainer}>
                  <FormikCheckbox
                    name="private"
                    label="Make this improvement private?"
                    trueValue={true}
                    falseValue={false}
                  />
                  <LockIcon className={styles.lockIcon} />
                </div>
                <div className={styles.description}>
                  <label htmlFor="description">Description</label>
                  {isGenerating ? (
                    <div className={styles.loadingMessage}>
                      Generating description{".".repeat(dotCount)}
                    </div>
                  ) : (
                    <Field
                      name="description"
                      component={Textarea}
                      value={values.description || typingDescription} // Sync with Formik's state or typing value
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      } // Allow editing
                      disabled={!isTypingComplete} // Disable editing until typing is complete
                      minHeight="250px"
                    />
                  )}
                </div>
                <div className={styles.row}>
                  <div className={styles.dueDate}>
                    <label htmlFor="date">Due Date</label>
                    <Field
                      name="date"
                      component={DatePickerField}
                      validateOnBlur={true}
                      validateOnChange={false}
                      maxDate={null}
                    />
                  </div>
                </div>
                {!isGenerating && (
                  <Button
                    text={loading ? "Adding..." : "Add New Improvement Project"}
                    type="submit"
                    className={styles.submitButton}
                    disabled={loading}
                  />
                )}
              </Form>
            </Modal>
            {showConfirmationPopup && (
              <ConfirmationPopup
                onConfirmClose={() => handleConfirmClose(resetForm)}
                onCancel={handleCancel}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default NewImprovementProjectModal;
