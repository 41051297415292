import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../components/Loader/PageLoader";
import DatePickerField from "../../components/FormikComponents/DatePickerField";
import Textarea from "../../components/Textarea/Textarea";
import styles from "./NewFinding.module.scss";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import FormikCheckbox from "../../components/FormikComponents/FormikCheckBox/FormikCheckBox";
import FormikUpload from "../../components/FormikComponents/FormikUpload/FormikUpload";
import Select from "../../components/Select/Select";
import * as Yup from "yup";
import axiosInstance from "../../utils/utils";
import { getOrganizationData } from "../../actions/organization";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import _ from "lodash";
import Swal from "sweetalert2";

const NewFinding = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [checklist, setChecklist] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [findingKey, setFindingKey] = useState(null);
  const formikRef = useRef(null);

  const sessionToken = localStorage.getItem("session_token");

  const { risksData, auditsData, findingsData } = useSelector(
    (state) => state.organization,
  );

  const risksArray = Object.values(risksData);
  const sortedRisks = _.sortBy(risksArray, "risk_id");

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Please provide a description."),
    reference: Yup.string().required("Reference cannot be empty."),
    chapter: Yup.string().required("Please select an associated chapter."),
    risk: Yup.string().required("Please select an associated risk."),
    attachments: Yup.array()
      .of(Yup.string())
      .required("File upload is required"),
  });

  const chapterOptions = _.uniqBy(
    sortedRisks.map((risk) => ({
      id: risk.qms_id,
      label: `${risk.qms_id} ${risk.qms_description}`,
    })),
    "id",
  );

  const riskOptions = sortedRisks.map((risk) => ({
    id: risk.risk_num,
    reference: risk.risk_id,
    label: `${risk.risk_id} ${risk.description}`,
  }));

  const formatDate = (inputDate) => {
    if (!inputDate) {
      return new Date().toISOString().split("T")[0];
    }
    return new Date(inputDate).toISOString().split("T")[0];
  };

  const handleDescriptionBlur = async (descriptionValue, setFieldValue) => {
    if (!descriptionValue) return;

    try {
      const params = new URLSearchParams();
      params.append("description", descriptionValue);

      const requestUrl = `/ai/finding_check?${params.toString()}`;
      const response = await axiosInstance.get(requestUrl);

      if (response.data.chapter) {
        setFieldValue("chapter", response.data.chapter);
      }
      if (response.data.checklist) {
        setChecklist(response.data.checklist);
      }
    } catch (error) {
      console.error("Error checking description:", error);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      const selectedRisk = riskOptions.find(
        (risk) => risk.label === values.risk,
      );
      const riskNumToSend = selectedRisk ? selectedRisk.id : null;

      if (!riskNumToSend) {
        actions.setSubmitting(false);
        setLoading(false);
        return;
      }

      const valuesToSend = {
        ...values,
        date: formatDate(values.date),
        risk: riskNumToSend,
      };

      const options = {
        method: "POST",
        url: `/nocodb/findings?org_id=${orgId}`,
        headers: { "session-token": localStorage.getItem("session_token") },
        data: [valuesToSend],
      };

      const response = await axiosInstance.request(options);

      const findingKey = response.data?.[0]; // Extract key from response

      if (findingKey) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `Your finding was registered as F-${findingKey}`,
          showConfirmButton: false,
          timer: 3000,
          toast: true,
        });
      }

      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          true,
          false,
          true,
          false,
          false,
        ),
      );
      actions.resetForm();
    } catch (error) {
      console.error("Error submitting finding:", error);
    } finally {
      setLoading(false);
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <PageLoader pageLoader text="Loading, thank you for your patience..." />
    );
  }

  return (
    <MainLayout>
      <div className={styles.newFinding}>
        <h1>New Finding</h1>
        {showPopup && (
          <div className={styles.popup}>
            Your finding was registered as <strong>{findingKey}</strong>.
          </div>
        )}
        <Formik
          innerRef={formikRef}
          initialValues={{
            description: "",
            date: "",
            reference: "",
            attachments: [],
            risk: "",
            status: "Done",
            chapter: "",
            private: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setLoading(true);
            handleSubmit(values, actions);
          }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {
            const chapterId = values.chapter.split(" ")[0];
            const filteredRiskOptions = riskOptions.filter((risk) =>
              risk.reference.startsWith(chapterId),
            );

            return (
              <Form className={styles.form}>
                <div className={styles.description}>
                  <label htmlFor="description">Description</label>
                  <Field
                    name="description"
                    component={Textarea}
                    onBlur={(e) =>
                      handleDescriptionBlur(
                        e.target.value,
                        formikRef.current.setFieldValue,
                      )
                    }
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.date}>
                    <label htmlFor="date">Date</label>
                    <Field name="date" component={DatePickerField} />
                  </div>
                  <div className={styles.reference}>
                    <label htmlFor="reference">Reference</label>
                    <Field name="reference" component={Input} />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.chapter}>
                    <label htmlFor="chapter">Associated Chapter</label>
                    <Field
                      name="chapter"
                      component={Select}
                      text="Select chapter"
                      options={chapterOptions}
                      onClick={() => setFieldValue("risk", "")}
                    />
                  </div>
                  <div className={styles.risk}>
                    <label htmlFor="risk">Associated Risk</label>
                    <Field
                      name="risk"
                      component={Select}
                      text="Select risk"
                      options={filteredRiskOptions}
                      disabled={!values.chapter}
                    />
                  </div>
                </div>
                <FormikCheckbox
                  name="status"
                  label="Needs follow-up?"
                  trueValue="Open"
                  falseValue="Done"
                />
                <div className={styles.privateContainer}>
                  <FormikCheckbox
                    name="private"
                    label="Make this finding private?"
                    trueValue={true}
                    falseValue={false}
                  />
                  <LockIcon className={styles.lockIcon} />
                </div>
                <div className={styles.attachment}>
                  <label htmlFor="attachments">Attachments</label>
                  <Field
                    name="attachments"
                    component={FormikUpload}
                    greenIcon
                  />
                </div>
                <Button
                  text="Add Finding"
                  type="submit"
                  disabled={loading}
                  className={styles.submitButton}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </MainLayout>
  );
};

export default NewFinding;
