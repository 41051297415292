import React from "react";
import { useField } from "formik";
import styles from "./FormikCheckBox.module.scss";
import { ReactComponent as CheckIcon } from "../../../icons/check-yellow.svg";

const FormikCheckbox = ({
  label,
  trueValue = true,
  falseValue = false,
  ...props
}) => {
  const [field, meta, helpers] = useField({ ...props, type: "checkbox" });
  const { setValue } = helpers;

  const toggleCheckbox = () => {
    const newValue = field.value === trueValue ? falseValue : trueValue;
    setValue(newValue);
  };

  return (
    <div className={styles.checkboxContainer}>
      <div
        className={`${styles.checkbox} ${
          field.value === trueValue ? styles.checked : ""
        }`}
        onClick={toggleCheckbox}
      >
        {field.value === trueValue && (
          <CheckIcon className={styles.checkboxTick} />
        )}
      </div>
      <label onClick={toggleCheckbox} className={styles.checkboxLabel}>
        {label}
      </label>
    </div>
  );
};

export default FormikCheckbox;
